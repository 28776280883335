import { Link as RouterLink, useLocation } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import GinoriLogo from './GinoriLogo'
  
  const items = [
    {
        text: 'Start Process',
        to: '/',
        icon: <PlayCircleIcon />
    },
    {
        text: 'Process History',
        to: '/history',
        icon: <ManageHistoryIcon />
    }
  ]
  
  export default function ResponsiveDrawer({onDrawerToggle, open}) {
    const { pathname } = useLocation()
  
    const drawer = (
      <div>
        <Link href='/'>
          <Box paddingX={'11.5%'} paddingTop={'11.5%'}>
            <GinoriLogo/>
          </Box>
        </Link>
        <List>
          {items.map((item) => (
              <ListItem
                key={item.text}
                component={RouterLink}
                to={item.to}
                sx={{
                  color: '#000',
                  '&& .Mui-selected, && .Mui-selected:hover': {
                    bgcolor: '#283579',
                    '&, & .MuiListItemIcon-root': {
                      color: 'white'
                    }
                  },
                  '& .MuiListItemButton-root:hover': {
                    bgcolor: '#ebedf0'
                  }
                }}
              >
                <ListItemButton
                  sx={{ justifyContent: { sm: 'center' } }}
                  selected={(pathname.includes(item.to) && item.to !== '/') || (item.to === '/' && pathname === '/')}
                >
                  <ListItemIcon sx={{ minWidth: '30px', color: '#283579' }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </div>
    )
  
    return (
      <>
        <Drawer
          elevation={3}
          variant='temporary'
          open={open}
          onClose={onDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            height: '100%',
          }}
        >
          {drawer}
        </Drawer>
      </>
    )
  }
  