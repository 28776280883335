import React, { useState } from 'react'
import { Checkbox, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormLabel, RadioGroup, Grid, FormControlLabel, Radio, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const FormGestioneImmaginiWeb = ({expanded, handleChange}) => {
    const { t } = useTranslation();
    const accordionId = "imageManagement";

    const [downloadFromWeb, setDownloadFromWeb] = useState(false);

    const selectedDownloadFromWeb = () => {
        setDownloadFromWeb(!downloadFromWeb)
    }

    return (
        <Accordion  expanded={expanded === accordionId} onChange={handleChange(accordionId)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}><h4>{t("labels.images")}</h4></AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item xs={4} display="flex" justifyContent="center" flexDirection="column">
                        <FormGroup md={{offset:1}}>
                            <FormControlLabel label={t("labels.addPreview")} control={<Checkbox id="imagesAddThumbnail" defaultChecked = {true}/>}></FormControlLabel>
                        </FormGroup>
                        <RadioGroup name="group_select_image_web" defaultValue="0">
                            <FormControlLabel label={t("labels.onlyMainImage")} value="0" control={<Radio />} />
                            <FormControlLabel label={t("labels.allImages")} value="1"control={<Radio />} />                                         
                        </RadioGroup> 
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="center">
                        <FormGroup id="gestioneImmaginiWebForm">
                            <FormLabel><h4>{t("labels.webImagesManagement")}</h4></FormLabel>
                            <FormLabel><Typography fontWeight="bold">{t("labels.maxRes")}</Typography></FormLabel>
                            <Grid container>
                                <Grid item xs={6} display="flex" justifyContent="center" flexDirection="column">
                                    <FormControlLabel label={t("labels.zipDownload")}  control={<Checkbox id="imagesAddDownloadInZip" checked={downloadFromWeb} onChange={() => selectedDownloadFromWeb()}/>}></FormControlLabel>
                                    <FormControlLabel label="URL"  control={<Checkbox id="imagesAddUrl" />}></FormControlLabel>
                                </Grid>
                                <Grid item xs={6} display="flex" justifyContent="center">
                                    <RadioGroup name="group_image_extension" defaultValue="1">
                                        <FormControlLabel label="TIFF" value="0" control={<Radio />} />
                                        <FormControlLabel label={t("labels.png")} value="1" control={<Radio />} />
                                        <FormControlLabel label="JPG" value="2" control={<Radio />} />                                     
                                    </RadioGroup> 
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="center">
                        <FormGroup>
                            <FormLabel><h4>{t("labels.fileName")}</h4></FormLabel>
                            <RadioGroup name="group_image_naming" defaultValue="0">
                                <FormControlLabel label="SKU" value="0" control={<Radio />} />
                                <FormControlLabel label="UPC" value="1" control={<Radio />} />
                                <FormControlLabel label="EAN" value="2" control={<Radio />} />                                     
                            </RadioGroup> 
                        </FormGroup>
                    </Grid>
                </Grid>                   
            </AccordionDetails>
        </Accordion>
    )
}

export default FormGestioneImmaginiWeb