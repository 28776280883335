import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ReportIcon from '@mui/icons-material/Report';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, TableFooter } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import { FilterOptions } from '../models/FilterOptions';

const headCells = [
    {
        id: 'CreateDate',
        label: 'labels.CreateDate',
        sortable: true
    },
    {
        id: 'FinishDate',
        label: 'labels.FinishDate',
        sortable: true
    },
    {
        id: 'ElaborationTime',
        label: 'labels.ElaborationTime',
        sortable: false
    },
    {
        id: 'Result',
        label: 'labels.Result',
        sortable: false
    }
];

function HistoryTableHeader(props) {
    const { onRequestSort, orderBy, order } =
        props;

    const { t } = useTranslation();

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };



    return (
        <TableHead>
            <TableRow>
                <TableCell key={"actions"}></TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable
                            ? (<TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>)
                            : t(headCell.label)
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

HistoryTableHeader.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

function HistoryTableToolbar(props) {
    const { t } = useTranslation();
    const { filters, setFilters } = props;

    const handleShowNotFinishChange = (event) => {
        setFilters({ ...filters, showNotFinished: event.target.checked });
    };

    const handleFilterOptionChange = (value) => {
        setFilters({ ...filters, filter: value });
    }

    return (
        <Toolbar sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyItems: 'flex-start',
            width: '100%',
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
        }}>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {t("labels.HistoryTable")}
            </Typography>
            <Card sx={{
                flex: '1 1 100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: '5px'
            }}>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '10px' }}>
                    <Chip
                        sx={{ flex: 1, marginRight: '5px' }}
                        label={t('labels.LastWeek')}
                        clickable={true}
                        onClick={() => handleFilterOptionChange(FilterOptions.LastWeek)}
                        color={filters.filter === FilterOptions.LastWeek ? 'primary' : undefined}
                    />
                    <Chip
                        sx={{ flex: 1, marginRight: '5px' }}
                        label={t('labels.LastMonth')}
                        clickable={true}
                        onClick={() => handleFilterOptionChange(FilterOptions.LastMonth)}
                        color={filters.filter === FilterOptions.LastMonth ? 'primary' : undefined}
                    />
                    <Chip
                        sx={{ flex: 1, marginRight: '5px' }}
                        label={t('labels.Last3Months')}
                        clickable={true}
                        onClick={() => handleFilterOptionChange(FilterOptions.Last3Months)}
                        color={filters.filter === FilterOptions.Last3Months ? 'primary' : undefined}
                    />
                    <Chip
                        sx={{ flex: 1 }}
                        label={t('labels.All')}
                        clickable={true}
                        onClick={() => handleFilterOptionChange(FilterOptions.All)}
                        color={filters.filter === FilterOptions.All ? 'primary' : undefined}
                    />
                </div>
                <FormControlLabel
                    sx={{ flex: 2, alignSelf: 'flex-end' }}
                    label={t('labels.ShowNotFinished')}
                    control={
                        <Checkbox
                            checked={filters.showNotFinished}
                            onChange={handleShowNotFinishChange}
                        />
                    } />
            </Card>
        </Toolbar>
    );
}

HistoryTableToolbar.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired
}

export default function HistoryTable(props) {
    const { order, setOrder, orderBy, setOrderBy, pagination, setPagination, filters, setFilters, data, totalRows } = props;
    const [selected, setSelected] = React.useState({});
    const { t } = useTranslation();
    const navigate = useNavigate()

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPagination({
            ...pagination,
            pageNumber: newPage
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination({
            pageSize: parseInt(event.target.value, 10),
            pageNumber: 0
        })
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        pagination.pageNumber > 0 ? Math.max(0, (1 + pagination.pageNumber) * pagination.pageSize - totalRows) : 0;


    const formatDate = (date) => {
        return moment(date).format("dddd, MMMM D YYYY, h:mm:ss a")
    }

    const formatTime = (timeSpan) => {
        return moment.duration(timeSpan).humanize();
    }

    const openProcessingDetail = (processingSessionId) => {
        navigate(`/steps/${processingSessionId}`);
    }

    const renderResult = (result) => {
        if (result === "Processing")
            return (
                <Tooltip title={t("labels.Processing")}>
                    <CircularProgress size={20} />
                </Tooltip>
            );
        else if (result === "Error")
            return (
                <Tooltip title={t("labels.Error")}>
                    <ReportIcon color='error' />
                </Tooltip>
            );
        else
            return (
                <Tooltip title={t("labels.Done")}>
                    <CheckCircleIcon color='success' />
                </Tooltip>
            );
    }

    return (
        <Box sx={{ width: '96%', marginLeft: 5, marginRight: 5 }}>
            <HistoryTableToolbar filters={filters} setFilters={setFilters} />
            <TableContainer>
                <Table
                    sx={{
                        minWidth: 750,
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 }
                    }}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <HistoryTableHeader
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {data?.map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >

                                    <Tooltip title={t("labels.Details")} enterDelay={500}>
                                        <TableCell>
                                            <IconButton onClick={() => openProcessingDetail(row.id)}>
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </TableCell>
                                    </Tooltip>
                                    <TableCell align="left">{formatDate(row.createDate)}</TableCell>
                                    <TableCell align="left">{row.finishDate ? formatDate(row.finishDate) : t("labels.NotFinished")}</TableCell>
                                    <TableCell align="left">{row.elaborationTime ? formatTime(row.elaborationTime) : ""}</TableCell>
                                    <TableCell align="left">{renderResult(row.result)}</TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        {totalRows === 0 && (
                            <TableRow
                                style={{
                                    height: 53,
                                }}
                            >
                                <TableCell align='center' colSpan={6} >
                                    {t('labels.NoData')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={totalRows}
                                rowsPerPage={pagination.pageSize}
                                page={pagination.pageNumber}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={
                                    ({ from, to, count }) => {
                                        return '' + from + '-' + to + ` ${t("labels.of")} ` + count
                                    }
                                }
                                labelRowsPerPage={t("labels.RowsPerPage")}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}

HistoryTable.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    setOrder: PropTypes.func.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    setPagination: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    data: PropTypes.array,
    totalRows: PropTypes.number.isRequired
};