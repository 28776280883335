import React from 'react'
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { Checkbox, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormLabel, Typography, Grid, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormListiniMercati = ({lista, expanded, handleChange}) => {
    const { t } = useTranslation();
    const accordionId = "pricesMarkets";

    function checkForAtLeastOneListino(event){
        let listiniList = $("input[id^='listiniForm']:checked").val();
        if(!listiniList){
            event.target.checked = true;
        }
    }
    function renderListingOptions(){
        if(lista.listingOptions){
            return(
                lista.listingOptions.map(({key, label}) =>{
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`listiniForm-${key}`} name={key} defaultChecked = {key==='ECItalia'} onChange={checkForAtLeastOneListino}/>}/>
                })
            )
        }else{
            return ({})
        }
    }
    function renderMarketOptions(){
        if(lista.marketsOptions){
            return(
                lista.marketsOptions.map(({key, label}) =>{
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`mercatiWebForm-${key}`} name={key} />}/>
                })
            )
        }else{
            return ({})
        }
    }
  return (
    <Accordion expanded={expanded === accordionId} onChange={handleChange(accordionId)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h4>{t("labels.priceListMarkets")}</h4></AccordionSummary>
        <AccordionDetails>
            <Grid container>
                <Grid item xs={6} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}} id="listiniForm">
                        <FormLabel><h4>{t("labels.priceList")}</h4></FormLabel>
                        <FormLabel><Typography fontWeight="bold">{t("labels.priceListDesc")}</Typography></FormLabel>
                        {
                            renderListingOptions()
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center">
                    <FormGroup md={{ offset:1}} id="mercatiWebForm">
                        <FormLabel><h4>{t("labels.markets")}</h4></FormLabel>
                        <FormLabel><Typography fontWeight="bold">{t("labels.marketsDesc")}</Typography></FormLabel>
                        {
                            renderMarketOptions()
                        }
                    </FormGroup>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
  )
}

export default FormListiniMercati