import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, RadioGroup, FormControlLabel, Radio, TextField, MenuItem  } from '@mui/material';

const SkuList = ({skuSelection,
    skuSelectionHandler, 
    collections, 
    selectedCollections, 
    selectedCollectionsHandler,
    disableHandler,
    handleSkuSelected,
    openSelect,
    handleSelectOpen,
    handleSelectClose}) => {
    const { t } = useTranslation();
  
    return(
        <Grid container direction="column" paddingLeft="20%">
            <Grid item xs={12}>
                <RadioGroup name="group_sku_selection" onChange={(event) => skuSelectionHandler(event.target.value)} defaultValue={"sku"}>
                    <FormControlLabel value="sku" control={<Radio />} label={t("labels.skuByHand")}/>
                    <FormControlLabel value="collection"  control={<Radio />} label={t("labels.skuFromCollections")} />                                         
                </RadioGroup> 
            </Grid>
            <Grid item xs={12} >
                { skuSelection && 
                    <TextField
                        id="outlined-multiline-static"
                        name="numeroSku"
                        label={t("placeholders.insertSkus")}
                        multiline
                        style={{ width: "60%"}}
                        rows={10}
                        onChange={handleSkuSelected}
                    />
                }
                { !skuSelection && 
                    <TextField
                        id="outlined-select-currency"
                        select 
                        label={t("placeholders.selectSkuCollection")}
                        SelectProps={{
                          multiple: true,
                          value: selectedCollections,
                          onChange: selectedCollectionsHandler,
                          sx:{maxWidth: 360, minWidth: 360},
                          open: openSelect,
                          onOpen: handleSelectOpen,
                          onClose: handleSelectClose
                        }}
                    >
                    {collections?.map((option) => (
                        <MenuItem 
                          key={option.value}
                          value={option.value}
                          disabled={disableHandler(option.value)}
                        >
                         {option.label}
                        </MenuItem>
                    ))}
                    </TextField>
                }
            </Grid>
        </Grid>
        
    )
}

export default SkuList;