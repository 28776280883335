import React from 'react'
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { Checkbox, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormLabel, Typography, Grid, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormLingue = ({lista, expanded, handleChange}) => {
    const { t } = useTranslation();
    const accordionId = "languages";

    function checkForAtLeastOneAxLanguage(event){
        let linguaAxList = $("input[id^='linguaAxForm']:checked").val();
        if(!linguaAxList){
            event.target.checked = true;
        }
    }

    function checkForAtLeastOneWebLanguage(event){
        let webLanguageList = $("input[id^='linguaWebForm']:checked").val();
        if(!webLanguageList){
            event.target.checked = true;
        }
    }
    function renderAxLanguages() {
        if(lista.axLanguagesOptions){
            return(
                lista.axLanguagesOptions.map(({key, label}) =>{
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`linguaAxForm-${key}`} name={key} defaultChecked = {key==='it'} onChange={checkForAtLeastOneAxLanguage} />}/>
                })
            )
        }else{
            return ({})
        }
    }
    function renderLanguages() {
        if(lista.languagesOptions){
            return(
                lista.languagesOptions.map(({key, label}) =>{
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`linguaWebForm-${key}`} name={key} label= {label} defaultChecked = {key==='it'} onChange= {checkForAtLeastOneWebLanguage}/>}/>
                })
            )
        }else{
            return ({})
        }
    }
    
  return (
    <Accordion expanded={expanded === accordionId} onChange={handleChange(accordionId)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h4>{t("labels.languages")}</h4></AccordionSummary>
        <AccordionDetails>
            <Grid container>
                <Grid item xs={6} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}} id="linguaAxForm">
                        <FormLabel><h4>{t("labels.AXLanguage")}</h4></FormLabel>
                        <FormLabel><Typography fontWeight="bold">{t("labels.AXLanguageDesc")}</Typography></FormLabel>
                        {
                            renderAxLanguages()
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center">
                    <FormGroup md={{ offset:1}} id="linguaWebForm">
                        <FormLabel><h4>{t("labels.webLanguage")}</h4></FormLabel>
                        <FormLabel><Typography fontWeight="bold">{t("labels.webLanguageDesc")}</Typography></FormLabel>
                        {
                            renderLanguages()
                        }
                    </FormGroup>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
  )
}

export default FormLingue