import { CircularProgress } from '@mui/material'

const loadingId = 'load-waiter'


export default function LoadWaiter({
  loadWaiterId,
  loading,
  fallback,
  children
}) {
  if (loading) {
    if (fallback) {
      return fallback
    } else {
      return (
        <span
          id={loadWaiterId !== undefined ? loadWaiterId + '-' + loadingId : loadingId}
          style={{
            width: '100%',
            height: '100%',
            padding: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </span>
      )
    }
  } else {
    return (
      <span id={loadWaiterId !== undefined ? loadWaiterId + '-' + loadingId : loadingId}>
        {children}
      </span>
    )
  }
}
