import React from 'react'
import { CircularProgress } from '@mui/material'

const FullPageLoader = () => {
  return (
    <div className = "loader-container">
        <div className = "loader">
            <CircularProgress/>
        </div>

    </div>
  )
}

export default FullPageLoader