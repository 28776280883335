import { useEffect, useState } from "react";
import { Box, Typography, IconButton, Alert, CircularProgress, Avatar, colors } from "@mui/material"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import LinearProgress from '@mui/material/LinearProgress';
import StepButton from '@mui/material/StepButton';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useParams } from "react-router";
import i18next from 'i18next';
import getProcessingSessionById from "../fetchers/GetProcessingSessionById";
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FullPageLoader from '../components/FullPageLoader';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { PageLayout } from "../components/PageLayout";
import CustomToaster from '../components/CustomToaster';
import relaunchStep from "../fetchers/RelaunchStep";
import ReplayIcon from '@mui/icons-material/Replay';

const StepsPage = () => {
    const [processingSession, setProcessingSession] = useState({})
    const [excelSteps, setExcelSteps] = useState([])
    const [imagesSteps, setImagesStep] = useState([])
    const [notifyExcelSteps, setNotifyExcelSteps] = useState([])
    const [notifyImagesSteps, setNotifyImagesStep] = useState([])
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(true);
    const [showToasterServerError, setShowToasterServerError] = useState(false);
    const [selectedStep, setSelectedStep] = useState(false);
    const [serverError, setServerError] = useState("");
    const { t } = useTranslation();
    const { processingSessionId } = useParams()
    const stepStyle = {
        ".MuiStepIcon-root.Mui-completed": { color: "green" },
    }

    useEffect(() => {
        var timerId = setInterval(() => updateState(), 5000);

        return () => {
            clearInterval(timerId);
        }
    }, [selectedStep])

    const updateState = () => {
        try {
            getProcessingSessionInfo();
        } catch (e) {
            console.log(e);
        }
    }

    const downloadFile = (document) => {
        window.open(document.downloadUrl, '_blank').focus();
    }

    const getProcessingSessionInfo = async () => {

        try {
            var processingSession = await getProcessingSessionById(processingSessionId)
            const excelGeneratingStep = processingSession.processingSteps.filter(step => step.type === "Excel")
            const excelNotifyStep = processingSession.processingSteps.filter(step => step.type === "NotifyExcel")
            const imageZipGeneratingStep = processingSession.processingSteps.filter(step => step.type === "ImageZip")
            const imageZipNotifyStep = processingSession.processingSteps.filter(step => step.type === "NotifyImageZip")

            setExcelSteps(excelGeneratingStep);
            setNotifyExcelSteps(excelNotifyStep);
            setImagesStep(imageZipGeneratingStep);
            setNotifyImagesStep(imageZipNotifyStep);

            setProcessingSession(processingSession)

            if (loading && processingSession)
                setLoading(false);

            if (isProcessCompleted(processingSession)) {
                return setActiveStep(2);
            }

            if (areExcelStepsCompleted(excelGeneratingStep, excelNotifyStep)
                && selectedStep === false) {
                setActiveStep(1);
            }
        } catch (e) {
            console.log(e);
            setServerError(`errors.${e.detail ?? "GenericError"}`);
            setShowToasterServerError(true);
        }
    }

    const handleStepClick = (index) => {
        if (!isProcessCompleted(processingSession)) {
            setSelectedStep(true);
            setActiveStep(index);
        }
    };

    const relaunchSelectedStep = async (id, accessToken) => {
        await relaunchStep(id, accessToken, i18next.language);
        setLoading(false);
    }

    const handleRelaunchStep = async (id) => {
        setLoading(true);
        try {
            await relaunchSelectedStep(id);
        } catch (e) {
            setServerError(`errors.${e.detail ?? "GenericError"}`);
            setShowToasterServerError(true);
        }
        setLoading(false);
    }

    const areExcelStepsCompleted = (excelSteps, notifyExcelSteps) => excelSteps.length > 0 && excelSteps.findIndex(x => x.finishDate == null) === -1 && notifyExcelSteps.findIndex(x => x.finishDate == null) === -1;
    const areImagesStepsCompleted = () => imagesSteps.findIndex(x => x.finishDate == null) === -1 && imagesSteps.length > 0 && notifyImagesSteps.findIndex(x => x.finishDate == null) === -1;
    const isImageStepDisabled = () => imagesSteps.length === 0;
    const isExcelStepDisabled = () => excelSteps.length === 0;
    const isProcessCompleted = (processingSession) => processingSession.finishDate != null;
    const isProcessInError = () => processingSession.processingSteps.findIndex(x => x.error != null) !== -1;
    const areImagesStepsInError = () => imagesSteps.findIndex(x => x.error != null) !== -1 || notifyImagesSteps.findIndex(x => x.error != null) !== -1;
    const areExcelStepsInError = () => excelSteps.findIndex(x => x.error != null) !== -1 || notifyExcelSteps.findIndex(x => x.error != null) !== -1;

    const isStepComplete = (step) => step.finishDate != null && step.error == null;
    const isStepInError = (step) => step.finishDate != null && step.error != null;
    const stepHasFileToDownload = (step) => step.linkedDocuments.findIndex(x => x.downloadUrl != null) !== -1;
    const stepCanBeRelaunched = (step) => true;
    const getFirstFileToDownload = (step) => step.linkedDocuments.find(x => x.downloadUrl != null);
    const getPartLabel = (step) => `${step?.part ?? 0}/${step?.totalParts ?? 0}`;
    const mapStep = (step, runningLabel, completedLabel, errorLabel) => {
        if (isStepComplete(step))
            return (
                <Box flex={1} display="flex" gap={2} key={step.id} margin={2} width="60%" >
                    <TaskAltIcon style={{ color: "green", width: 25, height: 25 }} />
                    <Typography style={{ color: "green" }}>
                        {t(`labels.${completedLabel}`).replace("###", getPartLabel(step))}
                    </Typography>
                    {
                        stepHasFileToDownload(step) && (
                            <IconButton onClick={() => downloadFile(getFirstFileToDownload(step))} style={{ color: "green", width: 25, height: 25 }}>
                                <FileDownloadIcon />
                            </IconButton>
                        )
                    }
                </Box>
            );
        else if (isStepInError(step))
            return (
                <Box flex={1} display="flex" gap={2} key={step.id} width="60%" margin={2}>
                    <ReportProblemIcon style={{ color: "red", width: 25, height: 25 }} />
                    <Typography style={{ color: "red" }} marginBottom={"2%"} >
                        {t(`labels.${errorLabel}`).replace("###", getPartLabel(step)) + t(`errors.${step.error}`)}
                    </Typography>
                    {
                        stepCanBeRelaunched(step) && (
                            <IconButton onClick={() => handleRelaunchStep(step.id)} style={{ color: "red", width: 25, height: 25 }}>
                                <ReplayIcon />
                            </IconButton>
                        )
                    }
                </Box>
            );
        else
            return (
                <Box flex={1} display="flex" key={step.id} flexDirection={"row"} margin={2} justifyContent={"flex-start"} width="60%" >
                    <Typography style={{ color: "#1976d2" }} marginBottom={"2%"} flex={1}>
                        {t(`labels.${runningLabel}`).replace("###", getPartLabel(step))}
                    </Typography>
                    <Box flex={3} marginTop={"1%"}>
                        <LinearProgress />
                    </Box>
                </Box>
            );
    };


    const getStepsIcon = (isInError, isCompleted, isDisabled, stepIndex) => {
        if (isCompleted && !isInError) {
            <TaskAltIcon style={{ color: "green", width: 25, height: 25 }} />
        } else if (!isCompleted && !isDisabled) {
            return (
                <CircularProgress size={"25px"} />
            );
        } else if (isInError) {
            return (
                <ReportProblemIcon style={{ color: "red", width: 25, height: 25 }} />
            );
        } else {
            return (
                <Avatar sx={{ bgcolor: colors.grey[500], width: 25, height: 25, fontSize: '0.75rem' }}>{stepIndex}</Avatar>
            );
        }
    }

    const getStepProps = (processSteps, notifySteps) => {
        const generationInError = processSteps.findIndex(x => x.error != null) !== -1;
        const notifyInError = notifySteps.findIndex(x => x.error != null) !== -1;


        if (generationInError || notifyInError) {
            return {
                optional: (<Typography variant="caption" color="error">
                    {t("labels.stepError")}
                </Typography>),
                error: true
            }
        }
    }



    const renderExcelSteps = (
        <>
            {
                excelSteps.map((step) => mapStep(step, "excelGenerating", "excelGenerated", "excelGenerationError"))
            }
            {
                notifyExcelSteps.map((step) => mapStep(step, "excelNotifying", "excelNotified", "excelNotifyError"))
            }
        </>
    );

    const renderImagesSteps = (
        <>
            {
                imagesSteps.map((step) => mapStep(step, "imageZipGenerating", "imageZipGenerated", "imageZipGenerationError"))
            }
            {
                notifyImagesSteps.map((step) => mapStep(step, "imageZipNotifying", "imageZipNotified", "imageZipNotifyError"))
            }
        </>
    );

    return (
        <PageLayout>
            {loading ? (
                <FullPageLoader />
            ) : (
                <>
                    <Box width={"94%"} p={"3%"}>
                        {isProcessCompleted(processingSession)
                            ? (<Alert severity={isProcessInError() ? "error" : "success"} style={{ whiteSpace: 'pre-line' }}>{isProcessInError() ? t("labels.ProcessInError") : t("labels.ProcessCompleted")}</Alert>)
                            : (<Alert severity="info" style={{ whiteSpace: 'pre-line' }}>{t("labels.InProgress")}</Alert>)}
                    </Box>
                    <Box width={"94%"} p={"3%"}>
                        <Stepper activeStep={activeStep} alternativeLabel nonLinear>
                            <Step key="excel" completed={areExcelStepsCompleted(excelSteps, notifyExcelSteps)} sx={stepStyle}>
                                <StepButton color="inherit" onClick={() => handleStepClick(0)} icon={getStepsIcon(areExcelStepsInError(), areExcelStepsCompleted(excelSteps, notifyExcelSteps), isExcelStepDisabled(), 1)}>
                                    <StepLabel {...getStepProps(excelSteps, notifyExcelSteps)} >{t("labels.excelStepTitle")}</StepLabel>
                                </StepButton>
                            </Step>
                            <Step key="imageZip" completed={areImagesStepsCompleted()} disabled={isImageStepDisabled()} sx={stepStyle}>
                                <StepButton icon={getStepsIcon(areImagesStepsInError(), areImagesStepsCompleted(), isImageStepDisabled(), 2)} color="inherit" onClick={() => handleStepClick(1)}>
                                    <StepLabel {...getStepProps(imagesSteps, notifyImagesSteps)}>{t("labels.imagesStepTitle")}</StepLabel>
                                </StepButton>
                            </Step>
                            <Step key="done" completed={isProcessCompleted(processingSession)} sx={stepStyle}>
                                <StepLabel >{t("labels.doneStepTitle")}</StepLabel>
                            </Step>
                        </Stepper>
                        <div style={{ marginTop: 50, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            {
                                isProcessCompleted(processingSession) ? (
                                    <>
                                        {renderExcelSteps}
                                        {renderImagesSteps}
                                    </>
                                ) : activeStep === 0 ? renderExcelSteps : renderImagesSteps
                            }
                        </div>
                    </Box>
                </>
            )}
            {showToasterServerError && <CustomToaster showToaster={showToasterServerError} setShowToaster={setShowToasterServerError} message={t(serverError)} color='error' />}
        </PageLayout>
    )
}

export default StepsPage