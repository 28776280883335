import axiosContext from "../axios-context";

async function startProcess(jsonToSend) {
    const response = await axiosContext.post("/downloadSkuFile", jsonToSend, { 
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        } 
    })
    return response.data
}

export default startProcess
