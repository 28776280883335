import React from "react";
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { ReactComponent as EnIcon } from '../assets/icons/en.svg'
import { ReactComponent as ItIcon } from '../assets/icons/it.svg'

const languages = [
    { code: 'en-US', label: 'English' },
    { code: 'it-IT', label: 'Italiano' }
]
export const LanguagePicker = () => {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleLanguageSwitch = (selectedOption) => {
        if (i18n.language !== selectedOption) {
            i18n.changeLanguage(selectedOption);
        }
    }

    const getLanguageIcon = (language) => {
        switch (language) {
            case "en-US":
                return (<EnIcon style={{ borderRadius: 30 }} />);
            case "it-IT":
                return (<ItIcon style={{ borderRadius: 30 }}  />);
            default:
                return null;
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    const options = languages.map((language) => {
        if (language.code !== i18n.language) {
            return <MenuItem style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }} key={language.code} onClick={() => handleLanguageSwitch(language.code)}>
                {getLanguageIcon(language.code)}
                <span style={{ marginLeft: 10 }}>{language.label}</span>
            </MenuItem>
        }
    });

    return (
        <>
            <IconButton
                onClick={handleClick}
                size='small'
                aria-controls={anchorEl ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={anchorEl ? 'true' : undefined}
            >
                {getLanguageIcon(i18n.language)}
            </IconButton>
            <Menu
                id='account-menu'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{ mt: '45px' }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 3
                }}
            >
                {options}
            </Menu>
        </>
    );
}