
import React  from 'react';
import FormLingue from './FormLingue';
import FormListiniMercati from './FormListiniMercati';
import FormGestioneImmagini from './FormGestioneImmagini';
import FormAltro from './FormAltro';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Box, Chip  } from '@mui/material';

const Parameters = ({lista, inputLavorazione, handleInputLavorazione}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState('');

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return(
    <Grid container direction="column">
      <Grid item xs={12} marginBottom={3}>
        <h1>{t("labels.parameters")}</h1>
      </Grid>
      <Grid item xs={12} marginBottom={4} paddingLeft="10%" >
        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'flex-end', width: "80%", alignSelf: "center" }}>
          <Chip label="YYYY-MM-DD-hh-mm-ss" sx={{flex: 1}} />
          <TextField
            sx={{flex: 5}}
            id="inputLavorazioneForm"
            label={t("placeholders.insertJobName")}
            variant="standard"
            value={inputLavorazione}
            onChange={handleInputLavorazione}
          />
        </Box>
      </Grid>
      <Grid item xs={12} marginBottom={4}>
        <FormLingue lista = {lista} handleChange={handleAccordionChange} expanded={expanded}/>
        <FormListiniMercati lista = {lista} handleChange={handleAccordionChange} expanded={expanded}/>
        <FormGestioneImmagini handleChange={handleAccordionChange} expanded={expanded} />
        <FormAltro lista = {lista} handleChange={handleAccordionChange} expanded={expanded}/>
      </Grid>
    </Grid>
  )
}

export default Parameters
