import { Navigate, Route } from 'react-router-dom'
import { RequiredAuth } from './auth/SecureRoute.jsx'

// Pages
import SkuSearch from './pages/SkuSearch'
import StepsPage from './pages/StepsPage'
import HistoryPage from './pages/HistoryPage'


export default function getRoutes() {
    return [
        <Route key='/process' path='/process' element={<RequiredAuth />}>
            <Route path='' element={<SkuSearch />} />
        </Route>,
        <Route key='/steps/:processingSessionId' path='/steps/:processingSessionId' element={<RequiredAuth />}>
            <Route path='' element={<StepsPage />} />
        </Route>,
        <Route key='/history' path='/history' element={<RequiredAuth />}>
            <Route path='' element={<HistoryPage />} />
        </Route>,
        <Route key='/' path='/' element={<Navigate to='/process' replace />} />
    ]
}