import React from 'react'
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { Checkbox, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormLabel, RadioGroup, Radio, Grid, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormAltro = ({lista, expanded, handleChange}) => {
    const { t } = useTranslation();
    const accordionId = "other";

    function checkForAtLeastOneMeasurement(event){
        let misureList = $("input[id^='misureForm']:checked").val();
        if(!misureList){
            event.target.checked = true;
        }
    }
    
    function checkForAtLeastOneCapacity(event){
        let capacitaList = $("input[id^='capacitaForm']:checked").val();
        if(!capacitaList){
            event.target.checked = true;
        }
    }

    function renderMeasurements() {
        if(lista.measurementOptions){
            return(
                lista.measurementOptions.map(({key, label}) =>{
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`misureForm-${key}`} name={`${key}`} defaultChecked = {key===0} onChange={checkForAtLeastOneMeasurement} />}/>
                })
            )
        }else{
            return ({})
        }
    }
    function renderCapacities() {
        if(lista.capacityOptions){
            return(
                lista.capacityOptions.map(({key, label}) =>{
                    return <FormControlLabel key={key} label={label} control={<Checkbox id={`capacitaForm-${key}`} name={`${key}`} defaultChecked = {key===0} onChange={checkForAtLeastOneCapacity} />}/>
                })
            )
        }else{
            return ({})
        }
    }
    
  return (
    <Accordion expanded={expanded === accordionId} onChange={handleChange(accordionId)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}><h4>{t("labels.other")}</h4></AccordionSummary>
        <AccordionDetails>
            <Grid container>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.productCategory")}</h4></FormLabel>
                        <RadioGroup name="group_product_category" defaultValue="0">
                            <FormControlLabel label="Website" value="0" control={<Radio />} />
                            <FormControlLabel label="BI (DWH)" value="1" control={<Radio />} />                                     
                        </RadioGroup> 
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.collection")}</h4></FormLabel>
                        <RadioGroup name="group_collection" defaultValue="0">
                            <FormControlLabel label="Website" value="0" control={<Radio />} />
                            <FormControlLabel label="BI (DWH)" value="1" control={<Radio />} />                                     
                        </RadioGroup> 
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.measureUnit")}</h4></FormLabel>
                        {
                            renderMeasurements()
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.quantity")}</h4></FormLabel>
                        {
                            renderCapacities()
                        }
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.multipack")}</h4></FormLabel>
                        <RadioGroup name="group_multipack" defaultValue="0">
                            <FormControlLabel label={t("labels.web")} value="0" control={<Radio />} />
                            <FormControlLabel label={t("labels.notWeb")} value="1" control={<Radio />} />                                     
                        </RadioGroup> 
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.html")}</h4></FormLabel>
                        <RadioGroup name="group_html" defaultValue="1">
                            <FormControlLabel label={t("labels.html")} value="0" control={<Radio />} />
                            <FormControlLabel label={t("labels.plain")} value="1" control={<Radio />} />                                     
                        </RadioGroup> 
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.decor")}</h4></FormLabel>
                        <RadioGroup name="group_decoration" defaultValue="0">
                            <FormControlLabel label={t("labels.decor")} value="0" control={<Radio />} />
                            <FormControlLabel label={t("labels.white")} value="1" control={<Radio />} />                                     
                        </RadioGroup> 
                    </FormGroup>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center">
                    <FormGroup md={{offset:1}}>
                        <FormLabel><h4>{t("labels.duplicates")}</h4></FormLabel>
                        <RadioGroup name="group_duplicates" defaultValue="1">
                            <FormControlLabel label={t("labels.delete")} value="0" control={<Radio />} />
                            <FormControlLabel label={t("labels.allow")} value="1" control={<Radio />} />                                     
                        </RadioGroup> 
                    </FormGroup>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
  )
}

export default FormAltro