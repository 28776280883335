import '../App.css';
import SkuList from '../components/SkuList';
import Parameters from '../components/Parameters';
import { useEffect, useState } from 'react'
import $ from 'jquery'
import React from 'react';
import FullPageLoader from '../components/FullPageLoader';
import CustomToaster from '../components/CustomToaster';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Divider, Grid } from '@mui/material';
import startProcess from '../fetchers/StartProcess';
import { useNavigate } from 'react-router';
import loadParameters from '../fetchers/LoadParameters';
import { PageLayout } from '../components/PageLayout';
import { useOktaAuth } from '@okta/okta-react'

function SkuSearch() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [listaParametri, setListaParametri] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showToasterValidation, setShowToasterValidation] = useState(false);
    const [showToasterServerError, setShowToasterServerError] = useState(false);
    const validationErrorMessage = t("messages.validationError");
    const serverErrorMessage = t("messages.serverError");
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [selectedSkus, setSelectedSkus] = useState([]);
    const [inputLavorazione, setInputLavorazione] = useState("");
    const [skuSelection, setSkuSelection] = useState(true);
    const { authState } = useOktaAuth();
    const [openSelect, setOpenSelect] = useState(false)

    const onSubmit = (event) => {
        event.preventDefault();
        let valido = CheckForValidation(event.target);
        if (valido) {
            startProcessCall(event)
        }
        else setShowToasterValidation(true);
    }

    const selectedSkuOption = (option) => {
        if (option === 'sku') {
            setSkuSelection(true)
            setSelectedCollections([])
        }
        else {
            setSkuSelection(false)
        }
    }

    const disableSelctionOption = (value) => {
        return selectedCollections.indexOf(value) === -1 && (selectedCollections.length >= 2)
    }

    const handleSelectOpen = () => {
        setOpenSelect(true);
    }

    const handleSelectClose = () => {
        setOpenSelect(false);
    }

    const handledCollectionSelected = (e) => {
        const eventValue = Array.isArray(e.target.value) ? e.target.value : []; 
        setSelectedCollections(eventValue);
        if(eventValue.length == 2){
            setOpenSelect(false);
        }
    }

    const handleSkuSelected = (e) => {
        setSelectedSkus(e.target.value.split('\n'));
    }

    const CheckForValidation = (target) => {
        let atLeastOneLinguaAx = false;
        let atLeastOneLinguaWeb = false;
        let atLeastOneListino = false;
        if (skuSelection) {
            if (selectedSkus.lenght === 0)
                return false;
        }
        else {
            if (selectedCollections.length === 0 || selectedCollections.length > 2)
                return false;
        }

        let linguaAxList = $("input[id^='linguaAxForm']:checked");
        for (let i = 0; i < linguaAxList.length; i++) {
            if (linguaAxList[i].checked) {
                atLeastOneLinguaAx = true;
            }
        }
        if (!atLeastOneLinguaAx)
            return false;

        let linguaWebList = $("input[id^='linguaWebForm']:checked");
        for (let i = 0; i < linguaWebList.length; i++) {
            if (linguaWebList[i].checked) {
                atLeastOneLinguaWeb = true;
            }
        }
        if (!atLeastOneLinguaWeb)
            return false;

        let listiniList = $("input[id^='listiniForm']:checked");
        for (let i = 0; i < listiniList.length; i++) {
            if (listiniList[i].checked) {
                atLeastOneListino = true;
            }
        }

        if (!atLeastOneListino)
            return false;
        return true;
    }


    const GenerateJsonFromForm = (target) => {
        let listone = [];
        let skus = [];
        let filename = "";
        let axlanguagesKeys = [];
        let languagesKeys = [];
        let listingKeys = [];
        let marketsKeys = [];
        let imagesAddThumbnail;
        let imagesAddDownloadInZip;
        let imagesAddUrl;
        let imageExtension;
        let imagesCollection;
        let damImagesAddDownloadInZip;
        let productCategorySource;
        let collectionSource;
        let lenghtUnitOfMeasurementSelected = [];
        let capacityUnitOfMeasurementSelected = [];
        let multipack;
        let html;
        let imageNaming;
        let decoration;
        let duplicates;

        // gruppi di checkbox
        let linguaAxList = $("input[id^='linguaAxForm']:checked");
        for (let i = 0; i < linguaAxList.length; i++) {
            if (linguaAxList[i].checked) {
                axlanguagesKeys.push(linguaAxList[i].name);
            }
        }

        filename = moment().format('YYYY-MM-DD HH_mm_ss') + "-" + inputLavorazione
        let linguaWebList = $("input[id^='linguaWebForm']:checked");
        for (let i = 0; i < linguaWebList.length; i++) {
            if (linguaWebList[i].checked) {
                languagesKeys.push(linguaWebList[i].name);
            }
        }

        let listiniList = $("input[id^='listiniForm']:checked");
        for (let i = 0; i < listiniList.length; i++) {
            if (listiniList[i].checked) {
                listingKeys.push(listiniList[i].name);
            }
        }

        let mercatiWebList = $("input[id^='mercatiWebForm']:checked");
        for (let i = 0; i < mercatiWebList.length; i++) {
            if (mercatiWebList[i].checked) {
                marketsKeys.push(mercatiWebList[i].name);
            }
        }

        let misureList = $("input[id^='misureForm']:checked");
        for (let i = 0; i < misureList.length; i++) {
            if (misureList[i].checked) {
                lenghtUnitOfMeasurementSelected.push(parseInt(misureList[i].name));
            }
        }

        let capacitaList = $("input[id^='capacitaForm']:checked");
        for (let i = 0; i < capacitaList.length; i++) {
            if (capacitaList[i].checked) {
                capacityUnitOfMeasurementSelected.push(parseInt(capacitaList[i].name));
            }
        }

        // checkbox "speciali"
        imagesAddThumbnail = $("#imagesAddThumbnail")[0].checked;
        imagesAddDownloadInZip = $("#imagesAddDownloadInZip")[0].checked;
        imagesAddUrl = $("#imagesAddUrl")[0].checked;

        // radiobutton
        imagesCollection = $("input[name='group_select_image_web']:checked").val();
        imageExtension = $("input[name='group_image_extension']:checked").val();
        productCategorySource = $("input[name='group_product_category']:checked").val();
        collectionSource = $("input[name='group_collection']:checked").val();
        multipack = $("input[name='group_multipack']:checked").val();
        html = $("input[name='group_html']:checked").val();
        imageNaming = $("input[name='group_image_naming']:checked").val();
        decoration = $("input[name='group_decoration']:checked").val();
        duplicates = $("input[name='group_duplicates']:checked").val();

        listone = {
            "skus": selectedSkus,
            "collection": selectedCollections,
            filename,
            "axlanguagesKeys": axlanguagesKeys,
            "languagesKeys": languagesKeys,
            "listingKeys": listingKeys,
            "marketsKeys": marketsKeys,
            imagesAddThumbnail,
            imagesCollection,
            "WebImagesAddDownloadInZip": imagesAddDownloadInZip,
            "WebImagesAddUrl": imagesAddUrl,
            "ImageExtension": imageExtension,
            "DamImagesAddDownloadInZip": damImagesAddDownloadInZip,
            productCategorySource,
            collectionSource,
            "lenghtUnitOfMeasurementKeys": lenghtUnitOfMeasurementSelected,
            "capacityUnitOfMeasurementKeys": capacityUnitOfMeasurementSelected,
            //lenghtUnitOfMeasurement = $("input[name='group_measurement']:checked").val(); // da levare
            //capacityUnitOfMeasurement = $("input[name='group_quantity']:checked").val();  // da levare
            multipack,
            html,
            imageNaming,
            decoration,
            duplicates
        }
        return JSON.stringify(listone);
    }

    useEffect(() => {
        FetchParameters();

        i18next.on('languageChanged', languageChangeHandler)

        return function cleanup() {
            i18next.off('languageChanged', languageChangeHandler);
        }
    }, [authState])

    const languageChangeHandler = () => {
        FetchParameters()
    }

    const FetchParameters = async () => {

        setLoading(true);
        function addAdditionalLists(data) {
            let measurements = [
                { key: 0, label: 'cm' },
                { key: 1, label: 'in' },
            ];
            let capacities = [
                { key: 0, label: 'ml' },
                { key: 1, label: 'cc' },
                { key: 2, label: 'oz' },
            ]
            data.measurementOptions = measurements;
            data.capacityOptions = capacities;

        }
        loadParameters()
            .then(data => {
                addAdditionalLists(data)
                setListaParametri(data)
                setLoading(false);
            })
            .catch((e) => {
                setShowToasterServerError(true);
                setLoading(false);
            });
    }

    const startProcessCall = async (event) => {
        setLoading(true);

        const jsonToSend = GenerateJsonFromForm(event.target);

        startProcess(jsonToSend)
            .then((response) => {
                const processingSessionId = response.processingSessionId
                setLoading(false);
                navigate(`/steps/${processingSessionId}`)
            })
            .catch((error) => {
                setShowToasterServerError(true);
                setLoading(false);
            });
    }


    return (
        <PageLayout>
            <div className="App">
                <Grid container paddingLeft={12} paddingRight={12}>
                    <Grid item xs={12} marginLeft={10}>
                        <img
                            alt='logo'
                            src='https://www.google.com/url?sa=i&url=https%3A%2F%2Fencrypted-tbn2.gstatic.com%2Fimages%3Fq%3Dtbn%3AANd9GcTqJoPv0Olku5HrD9UQted2jyVL_f9bE-vg6INGr7uT_fsvLvE8&psig=AOvVaw3iYpVq8SEm5Vii9FG0Vw27&ust=1665760037819000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCLi2x-y93foCFQAAAAAdAAAAABAD'
                            width="150"
                        ></img>
                    </Grid>
                    <Grid item xs={12} >
                        <form>
                            <Grid container>
                                <Grid item xs={12} marginLeft={10}>
                                    <SkuList
                                        skuSelection={skuSelection}
                                        skuSelectionHandler={selectedSkuOption}
                                        collections={listaParametri?.collections}
                                        selectedCollectionsHandler={handledCollectionSelected}
                                        selectedCollections={selectedCollections}
                                        disableHandler={disableSelctionOption}
                                        handleSkuSelected={handleSkuSelected}
                                        openSelect={openSelect}
                                        handleSelectClose={handleSelectClose}
                                        handleSelectOpen={handleSelectOpen}
                                    />
                                </Grid>
                                <Grid item xs={12} marginTop={5} overflow="hidden">
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} marginLeft={10}>
                                    {listaParametri && <Parameters lista={listaParametri} inputLavorazione={inputLavorazione} handleInputLavorazione={(e) => {
                                        setInputLavorazione(e.target.value);
                                    }} />}
                                </Grid>
                                <Grid item xs={12} marginLeft={10} marginBottom={5}>
                                    <Button color="primary" variant="contained" onClick={onSubmit} style={{ width: "100%" }}>{t("buttons.send")}</Button>
                                </Grid>
                            </Grid>
                            {showToasterValidation && <CustomToaster showToaster={showToasterValidation} setShowToaster={setShowToasterValidation} message={validationErrorMessage} color='warning' />}
                            {showToasterServerError && <CustomToaster showToaster={showToasterServerError} setShowToaster={setShowToasterServerError} message={serverErrorMessage} color='error' />}

                        </form>
                    </Grid>
                </Grid>
                {loading && <FullPageLoader />}


            </div>
        </PageLayout>
    );
}

export default SkuSearch;