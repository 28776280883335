import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";

export const oktaAuth = new OktaAuth({
    issuer: 'https://signin.kering.com/oauth2/default',
    clientId: '0oaa15m1q7xLATDgj417',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['offline_access', 'openid', 'email', 'profile' ],
});


export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
};