import axiosContext from "../axios-context";

async function relaunchStep(id) {
    const jsonToSend = JSON.stringify({ processingStepId: id })
    const response = await axiosContext.post("/api/ProcessingSession/relaunch", jsonToSend, { 
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        } 
    })
    return response
}

export default relaunchStep
