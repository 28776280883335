import React from "react";
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const AccountMenu = () => {
    const { authState, oktaAuth } = useOktaAuth()
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [account, setAccount] = useState(null);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
          setAccount(undefined)
        } else {
          // possible to use setUserInfo(authState.idToken?.claims) for less claims
    
          // '/userinfo' okta endpoint
          if (!account) {
            oktaAuth.getUser().then((info) => {
                setAccount(info)
    
              window.sessionStorage.setItem('nameInSession', `${info.given_name} ${info.family_name}`)
              window.sessionStorage.setItem(
                'nameInitialsInSession',
                `${info.given_name?.charAt(0)}${info.family_name?.charAt(0)}`
              )
            })
          }
        }
      }, [authState, oktaAuth, account])

    const logout = async () => {
        await oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}` })
        window.sessionStorage.clear()
    }

    const nameConcatenate = () => {
        if (window.sessionStorage.getItem('nameInSession'))
          return window.sessionStorage.getItem('nameInSession')
        else if (account) return `${account?.given_name} ${account?.family_name}`
      }
      const nameInitials = () => {
        if (window.sessionStorage.getItem('nameInitialsInSession'))
          return window.sessionStorage.getItem('nameInitialsInSession')
        else if (account)
          return `${account?.given_name?.charAt(0)}${account?.family_name?.charAt(0)}`
      }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    return (
        <>
            <IconButton
                onClick={handleClick}
                size='small'
                aria-controls={anchorEl ? 'account-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={anchorEl ? 'true' : undefined}
            >
                <Avatar sx={{ width: 38, height: 38, bgcolor: '#283579' }}>{nameInitials()}</Avatar>
            </IconButton>
            <Menu
                id='account-menu'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{ mt: '45px' }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 3
                }}
            >
                <MenuItem sx={{ cursor: 'default' }}>
                    <PersonOutlineIcon sx={{ mx: '5px' }} className='color-icon' />
                    <>{nameConcatenate() ?? 'Nome utente'}</>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <LogoutIcon sx={{ mx: '5px' }} className='color-icon' />
                    <>{t("buttons.logout")}</>
                </MenuItem>
            </Menu>
        </>
    );
}