import axiosContext from "../axios-context"

async function getProcessingSessionById(id) {
    const response = await axiosContext.get(`/api/ProcessingSession/${id}`, {
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        }
    })

    return response.data
}

export default getProcessingSessionById