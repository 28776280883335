import React from 'react'
import { useTranslation } from 'react-i18next';
import { Snackbar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { faBlackboard } from '@fortawesome/free-solid-svg-icons';

const theme = createTheme({
  components: {
    MuiSnackbar: {
      variants: [
        {
          props: { variant: "warning" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#e8993f",
              color: "black"
            }
          }
        },
        {
          props: { variant: "error" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#d65151",
              color: "white"
            }
          }
        }
      ]
    }
  }
});

const CustomToaster = ({showToaster, setShowToaster, message, color}) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showToaster}
        onClose={() => setShowToaster(false)}
        message={message}
        delay={3000} 
        variant={color}
      />
    </ThemeProvider>
  )
}

export default CustomToaster