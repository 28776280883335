import axios from "axios";
import { oktaAuth } from "./auth/OktaConfig.jsx";

const axiosContext = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosContext.interceptors.response.use(
    (res) => res,
    async (error) => {
        const originalConfig = error.config;
        if (error.response?.status === 401 && !originalConfig._retry && oktaAuth.getRefreshToken()) {
            originalConfig._retry = true;
            const token = await oktaAuth.token.renewTokens();
            await oktaAuth.tokenManager.setTokens(token);
            if (token && token.accessToken) {
                setAxiosAccessToken(token.accessToken.accessToken)
                originalConfig.headers['Authorization'] = `Bearer ${token.accessToken.accessToken}`;
                return axiosContext(originalConfig);
            } else {
                clearAxiosAccessToken();
            }
        }
        return Promise.reject(error?.response?.data)
    }
);

export const setAxiosAccessToken = (token) => {
    axiosContext.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  
  export const clearAxiosAccessToken = () => {
    delete axios.defaults.headers.common["Authorization"];
  }


  export const setAxiosLanguage = (language) => {
    axiosContext.defaults.headers.common['Accept-Language'] = language
  }

export default axiosContext;