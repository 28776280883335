import React, { useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, lightBlue, red, orange } from '@mui/material/colors';
import moment from 'moment';
import { Security, LoginCallback } from '@okta/okta-react'
import { clearAxiosAccessToken, setAxiosAccessToken } from './axios-context.js'
import { oktaAuth, restoreOriginalUri } from './auth/OktaConfig';

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import getRoutes from './routes.jsx'

const theme = createTheme({
    palette: {
        primary: {
            main: blue[500]
        },
        secondary: {
            main: lightBlue[500]
        },
        error: {
            main: red[500]
        },
        warning: {
            main: orange[500]
        }
    },
});

moment.locale('it', {
    months : [
      "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio",
      "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
    ],
    monthsShort : [
      "gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"
    ],
    weekdays : [
      "Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"
    ],
    weekdaysShort : [
      "dom", "lun", "mar", "mer", "gio", "ven", "sab"
    ],
    weekdaysMin : [
      "do", "lu", "ma", "me", "gi", "ve", "sa"
    ],
    longDateFormat : {
      LT : "HH:mm",
      LTS : "HH:mm:ss",
      L : "DD/MM/YYYY",
      LL : "D MMMM YYYY",
      LLL : "D MMMM YYYY LT",
      LLLL : "dddd D MMMM YYYY LT"
    },
    meridiemParse: /AM|PM/,
    isPM: function (input) {
      return input === 'PM';
    },
    meridiem: function (hours, minutes, isLower) {
      if (hours < 12) {
        return 'AM';
      } else {
        return 'PM';
      }
    },
    calendar : {
      sameDay: '[Oggi alle] LT',
      nextDay: '[Domani alle] LT',
      nextWeek: 'dddd [alle] LT',
      lastDay: '[Ieri alle] LT',
      lastWeek: function () {
        switch (this.day()) {
          case 0:
          case 6:
            return '[lo scorso] dddd [alle] LT';
          default:
            return '[lo scorso] dddd [alle] LT';
        }
      },
      sameElse: 'L'
    },
    relativeTime : {
      future : "tra %s",
      past : "%s fa",
      s : "alcuni secondi",
      m : "un minuto",
      mm : "%d minuti",
      h : "un'ora",
      hh : "%d ore",
      d : "un giorno",
      dd : "%d giorni",
      M : "un mese",
      MM : "%d mesi",
      y : "un anno",
      yy : "%d anni"
    },
    ordinalParse: /\d{1,2}º/,
    ordinal: '%dº',
    week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
  });  
  

  const onAuthRequired = async () => {
    if (oktaAuth.getRefreshToken()) {
      const token = await oktaAuth.token.renewTokens();
      await oktaAuth.tokenManager.setTokens(token);
      if (token && token.accessToken) {
        setAxiosAccessToken(token.accessToken.accessToken)
      } else {
        clearAxiosAccessToken();
        oktaAuth.signInWithRedirect();
      }
    } else {
      clearAxiosAccessToken();
      oktaAuth.signInWithRedirect();
    }
  }


const App = () => {

  useEffect(() => {
    const token = oktaAuth.getAccessToken()
    if (token) {
      setAxiosAccessToken(token)
    } else {
      clearAxiosAccessToken()
    }
  }, [oktaAuth])

  return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
              {getRoutes()}
              <Route key='/login/callback' path='/login/callback' Component={LoginCallback} />
            </Routes>
          </Security>
        </BrowserRouter>
      </ThemeProvider>
  )
}

export default App