import axiosContext from "../axios-context"

async function getSessionsHistory(orderBy, order, pageSize, offset, filter, showNotFinished) {
    const response = await axiosContext.get(`/api/ProcessingSession`, {
        params: {
            sortColumn: orderBy,
            descending: order === "desc",
            pageSize,
            offset,
            filter,
            showNotFinished
        },
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        }
    })

    return response.data
}

export default getSessionsHistory