import { PageLayout } from "../components/PageLayout";
import { useEffect, useState } from "react";
import getSessionsHistory from "../fetchers/GetSessionsHistory";
import CustomToaster from '../components/CustomToaster';
import HistoryTable from "../components/HistoryTable";
import FullPageLoader from '../components/FullPageLoader';
import { FilterOptions } from '../models/FilterOptions';


const HistoryPage = () => {
    const [paginatedHistory, setPaginatedHistory] = useState({});
    const [order, setOrder] = useState("desc")
    const [orderBy, setOrderBy] = useState("CreateDate");
    const [pagination, setPagination] = useState({
        pageSize: 5,
        pageNumber: 0
    });
    const [filters, setFilters] = useState({
        filter: FilterOptions.LastWeek,
        showNotFinished: false
    });
    const [serverError, setServerError] = useState("");
    const [showToasterServerError, setShowToasterServerError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setIsLoading(true);
        getPaginatedHistory();
        setIsLoading(false);
    }, [order, orderBy, pagination, filters])

    const getPaginatedHistory = () => {
        getSessionsHistory(orderBy, order, pagination.pageSize, pagination.pageNumber * pagination.pageSize, filters.filter, filters.showNotFinished)
            .then((paginatedResult) => {
                setPaginatedHistory(paginatedResult)
            }).catch((e) => {
                setServerError(`errors.${e.detail ?? "GenericError"}`);
                setShowToasterServerError(true);
            });
    }


    return (
        <PageLayout>
            <HistoryTable
                data={paginatedHistory?.data}
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                pagination={pagination}
                setPagination={setPagination}
                filters={filters}
                setFilters={setFilters}
                totalRows={paginatedHistory?.totalItemsCount ?? 0} />
            {showToasterServerError && <CustomToaster showToaster={showToasterServerError} setShowToaster={setShowToasterServerError} message={serverError} color='error' />}
            {isLoading && <FullPageLoader />}
        </PageLayout>
    )
}

export default HistoryPage