import React, { useState, useEffect } from "react";
import { AccountMenu } from "./AccountMenu";
import { LanguagePicker } from "./LanguagePicker";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next';
import ResponsiveDrawer from "./SidebarComponent";
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu'
import { useOktaAuth } from '@okta/okta-react'
import { setAxiosLanguage } from '../axios-context';
import i18next from 'i18next';
import moment from "moment";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */



export const PageLayout = (props) => {
    const { t, i18n } = useTranslation();
    const [openDrawer, setDrawerOpen] = useState(false);
    const { authState } = useOktaAuth()

    const onDrawerToggle = () => {
        setDrawerOpen(!openDrawer)
      }

      useEffect(() => {
        moment.locale(i18n.language);
        setAxiosLanguage(i18n.language);

        i18next.on('languageChanged', languageChangeHandler)

        return function cleanup() {
        i18next.off('languageChanged', languageChangeHandler);
        }
    }, [authState])

    const languageChangeHandler = () => {
        moment.locale(i18n.language);
        setAxiosLanguage(i18n.language);
    }

    return (
        <>
            <ResponsiveDrawer open={openDrawer} onDrawerToggle={onDrawerToggle} />
            <AppBar
                position='static'
                color='inherit'
                sx={{ borderRadius: 1 }}
                component='nav'
                elevation={1}
            >
                <Toolbar>
                    {(authState.isAuthenticated && <IconButton
                    aria-label='Apri menu'
                    edge='start'
                    onClick={onDrawerToggle}
                    sx={{ mr: 1 }}
                    >
                        <MenuIcon htmlColor='#000000DE' />
                    </IconButton>)}
                    <Typography component='span' variant='h5' sx={{ flexGrow: 1 }}>
                        Product Central
                    </Typography>
                    <LanguagePicker />
                    <AccountMenu /> 
                </Toolbar>
            </AppBar>

            <br />

            {props.children}
            
        </>
    );
};