import axiosContext from "../axios-context";

async function loadParameters() {
    const response = await axiosContext.get("/parameters", { 
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        } 
    })
    return response.data
}

export default loadParameters